import React from "react"
import CookieBanner from "./src/components/CookieBanner"
import "./src/styles/global.css"
import "./src/styles/swiper.css"
import { Helmet } from "react-helmet"
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        ></link>
        {/* <script async>
          {`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
}(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','LI-6273B24E24F92'));`}
        </script> */}
      </Helmet>
      {element}
      {/* <CookieBanner /> */}
    </>
  )
}

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== "production") {
//     return null
//   }

//   const pagePath = location
//     ? location.pathname + location.search + location.hash
//     : undefined
//   setTimeout(() => {
//     if (typeof gtag === "function") {
//       gtag("event", "page_view", { page_path: pagePath })
//     }
//   }, 100)
// }
export const shouldUpdateScroll = ({ routerProps }) => {
  const hasAnchor = !!routerProps.location.hash // (ie. /#features)

  return hasAnchor
}

export const onInitialClientRender = () => {
  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "/"
  const lang = pathname.startsWith("/en/") ? "en" : "de"
  document.documentElement.lang = lang

  function loadUsercentricsScript() {
    var usercentricsScript = document.createElement("script")
    usercentricsScript.id = "usercentrics-cmp"
    usercentricsScript.src =
      "https://app.usercentrics.eu/browser-ui/latest/loader.js"
    usercentricsScript.setAttribute("data-settings-id", "-RlVQSFFeUsyZk")
    usercentricsScript.async = true

    // Append the script to the document body
    document.body.appendChild(usercentricsScript)
  }

  function loadLeadinfoScript() {
    return (
      <script async>
        {`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
}(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','LI-6273B24E24F92'));`}
      </script>
    )
  }

  // Add an event listener to wait for the page to fully load
  window.addEventListener("load", loadUsercentricsScript)
  window.addEventListener("load", loadLeadinfoScript)
}

// function initGTM() {
//   if (window.isGTMLoaded) {
//     return false
//   }
//   window.isGTMLoaded = true
//   const script = document.createElement("script")
//   script.type = "text/javascript"
//   script.async = true
//   script.src = `https://www.googletagmanager.com/gtag/js?id=G-1HWWSNXFX5`
//   script.onload = () => {
//     window.dataLayer = window.dataLayer || []
//     function gtag() {
//       dataLayer.push(arguments)
//     }
//     gtag("js", new Date())
//     gtag("config", `G-1HWWSNXFX5`)
//   }
//   document.head.appendChild(script)
// }
// function loadGTM(event) {
//   initGTM()
//   event.currentTarget.removeEventListener(event.type, loadGTM)
// }
// export const onClientEntry = () => {
//   document.onreadystatechange = () => {
//     if (document.readyState !== "loading") {
//       setTimeout(initGTM, 3500)
//     }
//   }
//   document.addEventListener("scroll", loadGTM)
//   document.addEventListener("mousemove", loadGTM)
//   document.addEventListener("touchstart", loadGTM)
// }
