exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/BlogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-ecommerce-js": () => import("./../../../src/templates/Ecommerce.js" /* webpackChunkName: "component---src-templates-ecommerce-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-impressum-js": () => import("./../../../src/templates/impressum.js" /* webpackChunkName: "component---src-templates-impressum-js" */),
  "component---src-templates-job-detail-js": () => import("./../../../src/templates/JobDetail.js" /* webpackChunkName: "component---src-templates-job-detail-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-new-sub-page-js": () => import("./../../../src/templates/new-sub-page.js" /* webpackChunkName: "component---src-templates-new-sub-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-online-marketing-js": () => import("./../../../src/templates/online-marketing.js" /* webpackChunkName: "component---src-templates-online-marketing-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/Privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-reference-detail-js": () => import("./../../../src/templates/ReferenceDetail.js" /* webpackChunkName: "component---src-templates-reference-detail-js" */),
  "component---src-templates-references-js": () => import("./../../../src/templates/references.js" /* webpackChunkName: "component---src-templates-references-js" */),
  "component---src-templates-sub-page-js": () => import("./../../../src/templates/sub-page.js" /* webpackChunkName: "component---src-templates-sub-page-js" */),
  "component---src-templates-system-integration-js": () => import("./../../../src/templates/system-integration.js" /* webpackChunkName: "component---src-templates-system-integration-js" */),
  "component---src-templates-tos-js": () => import("./../../../src/templates/Tos.js" /* webpackChunkName: "component---src-templates-tos-js" */)
}

