import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  const acceptCookies = () => {
    setShowBanner(false);
    document.cookie = "cookieAccepted=true; expires=Fri, 31 Dec 2023 23:59:59 GMT; path=/; SameSite=None; Secure";
  };

  useEffect(() => {
    const cookieAccepted = document.cookie
      .split(';')
      .some((cookie) => cookie.trim().startsWith('cookieAccepted='));

    if (cookieAccepted) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }

    setInitialCheckDone(true);
  }, []);

  if (!initialCheckDone) {
    return null;
  }

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 w-full bg-[#161515] py-4 px-6 text-white ">
      <div className="flex justify-between items-center">
        <h1 className="" id="cookieBanner">
          This website uses cookies to ensure you get the best experience.
        </h1>
        <button
          className="bg-primary text-gray-900 px-4 md:px-6 py-2 font-normal rounded-full"
          id="btn-cookie"
          onClick={acceptCookies}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
